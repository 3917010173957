export const environment = {
  production: false,
  apiBaseUrl: 'https://b101d01as-backend01.azurewebsites.net',
  clientId: 'e0dac4cc-b3c4-4337-aaf7-dd6a1d85e6c5',
  authority: 'https://login.microsoftonline.com/fad277c9-c60a-4da1-b5f3-b3b8b34a82f9',
  redirectUri: 'https://dev.funding-data.education.gov.uk/.auth/login/aad/callback',
  scope: ['https://fundingdataserviceapidev/user_impersonation'],
  sysAdminGroup: 'AZURE-FDS-DV-SYSADMIN-USERS',
  useLocalHttpInterceptor: false,
  localToken: '',
  environment: 'dev'
};
